import React from "react"
import CarouselImg1 from "../../images/index/carousel_img_1"

const IndexHero = () => (
  <div className="hero_img">
    <CarouselImg1 />
  </div>
)

export default IndexHero
